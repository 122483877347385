// This file is used to generate the navigation menu for the web app.

const fullMenu = [
  {
    title: "Topics",
    sections: [
      {
        heading: "Topics",
        slug: "/tags/PXovYV/topics",
        testID: "navListTopics",
        tags: [],
      },
    ],
  },
  {
    title: "Channels",
    sections: [
      {
        heading: "Channels",
        slug: "/tags/6YlOrw/channels",
        testID: "navListChannels",
        tags: [],
      },
    ],
  },
  {
    title: "Activities & Routines",
    sections: [
      {
        heading: "Activity Type",
        slug: "/tags/QXBVXl/activity-type",
        testID: "navListActivityType",
        tags: [
          {
            name: "Breathe",
            description: "",
            slug: "/tags/ZwmOzX/breathe",
          },
          {
            name: "Guided Dance",
            description: "",
            slug: "/tags/0YZ0G2/guided-dance",
          },
          {
            name: "How-To",
            description: "",
            slug: "/tags/7Yjqnw/how-to",
          },

          {
            name: "Stretch",
            description: "",
            slug: "/tags/6Yl682/stretch",
          },
          {
            name: "Workout",
            description: "",
            slug: "/tags/xX0LZX/workout",
          },
        ],
      },
      {
        heading: "Routines",
        slug: "/tags/r26b2L/routines",
        testID: "navListRoutines",
        tags: [
          {
            name: "Class Routines",
            description: "",
            slug: "/tags/xX0ZwZ/class-routines",
          },
          {
            name: "Test Best",
            description: "",
            slug: "/tags/aYyW26/test-best",
          },
          {
            name: "Wake Up",
            description: "",
            slug: "/tags/9Y7928/wake-up",
          },
          {
            name: "Bedtime",
            description: "",
            slug: "/tags/eXAmX8/bedtime",
          },
        ],
      },
      {
        heading: "Holidays & Events",
        slug: "/tags/lYOA2a/holidays-and-events",
        testID: "navListHolidays",
        tags: [
          {
            name: "Wintertime",
            description: "",
            slug: "/tags/kYz425/wintertime",
          },
          {
            name: "New Year",
            description: "",
            slug: "/tags/72Gb1X/new-year",
          },
          {
            name: "Valentine's day",
            description: "",
            slug: "/tags/3Y8MXW/valentines-day",
          },
          {
            name: "Birthdays",
            description: "",
            slug: "/tags/eXdM23/birthdays",
          },
        ],
      },
    ],
  },
  {
    title: "Skills & Knowledge",
    sections: [
      {
        heading: "Curriculum",
        slug: "/tags/lYVL2g/curriculum",
        testID: "navListCurriculum",
        tags: [
          {
            name: "English Language Arts",
            description: "",
            slug: "/tags/eYRnYK/english-language-arts",
          },
          {
            name: "Math",
            description: "",
            slug: "/tags/NXQWwn/math",
          },
          {
            name: "Reading Practice",
            description: "",
            slug: "/tags/ywe7XK/reading-practice",
          },

          {
            name: "Science",
            description: "",
            slug: "/tags/EY9ZXb/science",
          },
          {
            name: "Social Studies",
            description: "",
            slug: "/tags/DYLowW/social-studies",
          },
        ],
      },
      {
        heading: "Grades",
        slug: "/tags/eYxDYg/grades",
        testID: "navListGrades",
        tags: [
          {
            name: "GoNoodle Junior (PreK)",
            description: "",
            slug: "/tags/lYOj8w/gonoodle-junior",
          },
          {
            name: "Kindergarten",
            description: "",
            slug: "/tags/GYpvD2/kindergarten",
          },
          {
            name: "1st Grade",
            description: "",
            slug: "/tags/9Y7l9X/1st-grade",
          },
          {
            name: "2nd Grade",
            description: "",
            slug: "/tags/rwqW5X/2nd-grade",
          },
          {
            name: "3rd Grade",
            description: "",
            slug: "/tags/zXNzWX/3rd-grade",
          },
        ],
      },
      {
        heading: "Sensory & Motor Skills",
        slug: "/tags/72GzXe/sensory-and-motor-skills",
        testID: "navListMotorSkills",
        tags: [
          {
            name: "Body Awareness",
            description: "",
            slug: "/tags/W2gQYz/body-awareness",
          },
          {
            name: "Coordination",
            description: "",
            slug: "/tags/L2nyXW/coordination",
          },
          {
            name: "Cross Lateral Skills",
            description: "",
            slug: "/tags/QXPOYz/cross-lateral-skills",
          },
          {
            name: "Gross Motor Skills",
            description: "",
            slug: "/tags/PY15Yl/gross-motor-skills",
          },
          {
            name: "Locomotor Skills",
            description: "",
            slug: "/tags/6Yl8Yk/locomotor-skills",
          },
        ],
      },
    ],
  },
  {
    title: "Games",
    sections: [
      {
        heading: "Games",
        slug: "/tags/0YZynw/game-on",
        testID: "navListGames",
        tags: [],
      },
    ],
  },
];

const compactMenu = [
  {
    name: "Search",
    slug: "/search",
  },
  {
    name: "Games",
    slug: "/tags/0YZynw/game-on",
  },
  {
    name: "Topics",
    slug: "/tags/PXovYV/topics",
  },
  {
    name: "Channels",
    slug: "/tags/6YlOrw/channels",
  },
  {
    name: "Activity Type",
    slug: "/tags/QXBVXl/activity-type",
  },
  {
    name: "Routines",
    slug: "/tags/r26b2L/routines",
  },
  {
    name: "Holidays & Events",
    slug: "/tags/lYOA2a/holidays-and-events",
  },
  {
    name: "Curriculum",
    slug: "/tags/lYVL2g/curriculum",
  },
  {
    name: "Grades",
    slug: "/tags/eYxDYg/grades",
  },
  {
    name: "Sensory & Motor Skills",
    slug: "/tags/72GzXe/sensory-and-motor-skills",
  },
];

export { fullMenu, compactMenu };
