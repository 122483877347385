import React from "react";
import PropTypes from "prop-types";
import NextLink from "next/link";
import { useRouter } from "next/router";
import {
  isLocalURL,
  isSubdomain,
  concatenateQueryParams,
  stripQueryString,
  decodeUri,
  invariant,
} from "@gonoodle/gn-universe-utils";

import { ROUTE_PREFIX } from "../../constants";
import api from "../../api";

const JSURL = require("jsurl");

const client = api();

function getRoutePath(href) {
  const urlParts = href.split("/");

  if (urlParts[1] === "") {
    return ROUTE_PREFIX.HOME;
  }

  switch (urlParts[1]) {
    case ROUTE_PREFIX.PROFILES:
      return href;
    case ROUTE_PREFIX.CHAMP_COLLECTION:
      return href;
    case ROUTE_PREFIX.TROPHIES:
      return href;
    case ROUTE_PREFIX.ACCOUNT_SETTINGS:
      return href;
    case ROUTE_PREFIX.LOGIN:
      return href;
    case ROUTE_PREFIX.REGISTRATION:
      return href;
    case ROUTE_PREFIX.FAVORITES:
      return href;
    case ROUTE_PREFIX.COMPANY:
      return href;
    case ROUTE_PREFIX.SEARCH:
      return href;
    case ROUTE_PREFIX.CURRICULUM:
      return href;
    case ROUTE_PREFIX.VIDEOS:
      return `/${ROUTE_PREFIX.VIDEOS}/[...identity]`;
    case ROUTE_PREFIX.ACTIVITIES:
      return `/${ROUTE_PREFIX.ACTIVITIES}/[...identity]`;
    case ROUTE_PREFIX.GAMES:
      return `/${ROUTE_PREFIX.GAMES}/[...identity]`;
    case ROUTE_PREFIX.TAGS:
      return `/${ROUTE_PREFIX.TAGS}/[...identity]`;

    default:
      return undefined;
  }
}

function getQueryParams(href) {
  const queryString = href.split("?")[1];
  const queryParams = {};

  if (!queryString) {
    return queryParams;
  }

  queryString.split("&").forEach((param) => {
    const [key, value] = param.split("=");
    queryParams[key] = decodeUri(value);
  });

  return queryParams;
}

function resolveHref(href, { referrer, ...rest }) {
  const hrefWithoutQueryParams = stripQueryString(href);
  const routePath = getRoutePath(hrefWithoutQueryParams);
  const queryParams = getQueryParams(href);

  if (routePath) {
    if (!referrer) {
      return concatenateQueryParams(`${routePath}`, {
        ...queryParams,
        ...rest,
      });
    }

    return concatenateQueryParams(`${routePath}`, {
      ...rest,
      ...queryParams,
      referrer: JSURL.stringify(referrer),
    });
  }

  invariant(false, `No registered path found for href: ${href}`);
  return undefined;
}

function resolveExternalHref(href, { referrer, ...rest }) {
  return concatenateQueryParams(`/${ROUTE_PREFIX.EXTERNAL_LINK_DISCLAIMER}`, {
    href,
    referrer: JSURL.stringify(referrer),
    ...rest,
  });
}

const Link = React.forwardRef(function Link(
  { to, queryParams, children, events, legacyBehavior, onClick, ...rest },
  forwardedRef,
) {
  const router = useRouter();

  invariant(
    !(
      legacyBehavior &&
      children &&
      React.Children.toArray(children).length > 1
    ),
    `Link with legacyBehavior=true can only have one child got ${
      React.Children.toArray(children).length
    }`,
  );

  const href = React.useMemo(() => {
    if (isLocalURL(to)) {
      return resolveHref(to, queryParams);
    }

    return to;
  }, [to, queryParams]);

  const onRouteChange = () => {
    events.forEach((event) => {
      client.trackAnalyticsEvents(event).catch(() => {});
    });
  };

  const handleLinkPress = (e) => {
    if (!isLocalURL(to) && !isSubdomain(process.env.NEXT_PUBLIC_WEB_URL, to)) {
      e.preventDefault();
      router.push(resolveExternalHref(to, queryParams));
    }

    onRouteChange();
    onClick(e);
  };

  return (
    <NextLink
      href={href}
      as={to}
      onClick={legacyBehavior ? undefined : handleLinkPress}
      legacyBehavior={legacyBehavior}
      ref={forwardedRef}
      {...rest}
    >
      {legacyBehavior
        ? React.cloneElement(children, { onClick: handleLinkPress })
        : children}
    </NextLink>
  );
});

export default Link;

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  queryParams: PropTypes.shape({}),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.string.isRequired,
      properties: PropTypes.shape({}).isRequired,
    }),
  ),
  legacyBehavior: PropTypes.bool,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  queryParams: {},
  events: [],
  legacyBehavior: false,
  children: null,
  onClick: () => {},
};
